.scan-switch {
    margin-right: 1rem;
    color: black;

    & .MuiSwitch-colorSecondary.Mui-checked {
        color: $dark-green !important;
    }

    & .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
        background-color: $dark-green !important;
    }
}