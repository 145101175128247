.requirement-icon {

    display: flex;
    margin-bottom: 1.5rem;

    img {
        width: 2rem;

        &:first-child {
            margin-right: 1rem;
        }
    }
}