.splash-screen {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $dark-green;
    color: white;
}