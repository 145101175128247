.date-picker {
    width: 100%;
    border: $input-border !important;
    border-radius: $input-border-radius;
    transition: $input-transition;
    height: $input-height;

    & input {
        border: none !important;
    }


    &:focus-within {
        outline: none !important;
        border: 2px solid black !important;
    }


    .MuiInputBase-input {
        padding: 5px 10px !important;
    }

    .MuiInput-root,
    .MuiIconButton-root {
        height: 2.5rem;
        padding: 0;

        &:focus {
            outline: none !important;
            // border: 2px solid #2684ff !important;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom: none !important;
        content: '';
    }
}

input[type=text],
input {
    width: 100%;
    border: $input-border;
    border-radius: $input-border-radius;
    transition: $input-transition;
    height: $input-height;
    font-size: 1.2rem !important;
    padding: 5px 10px;
}

.validation-error {
    text-align: center;
    color: red;
    font-size: 1.1rem;
}