$height: 3rem;

.barcode {
    display: flex;
    position: relative;


    input[type=text] {
        height: $height;
        line-height: $height;
        width: 20rem;
        font-size: 1.5rem;
        background-color: #194c4f61;
        border: none;
        border-radius: 6px;
        padding-left: 56px;
    }


    img {
        position: absolute;
        width: 40px;
        margin: 9px;
        border-right: 1px solid black;
        padding-right: 5px;
    }

    &-container {
        display: flex;

        & .btn {
            height: $height;
        }
    }
}