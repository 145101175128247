$light-green: #2c5b5d;

.submitting-on-behalf {
    color: #ffffff;
    background-color: #C44900;
    padding: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 600px;
    margin: auto;

    .header-text {
        color: #ffffff;
        font-size: 16px;
    }

    &+.loadedContent {
        padding: 20px 20px 20px 20px;
        border-left: 1px solid #C44900;
        border-right: 1px solid #C44900;
        border-bottom: 1px solid #C44900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-bottom: 40px;
    }

    &+div {
        padding: 20px 20px 20px 20px;
        border-left: 1px solid #C44900;
        border-right: 1px solid #C44900;
        border-bottom: 1px solid #C44900;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-bottom: 40px;
        max-width: 600px;
        margin: auto;
    }

    &+p {
        width: 600px;
        margin: auto;
        border-left: 1px solid #C44900;
        border-right: 1px solid #C44900;

        &+div {
            width: 600px;
            margin: auto;
            border-left: 1px solid #C44900;
            border-right: 1px solid #C44900;


            &+div {
                width: 600px;
                margin: auto;
                border-left: 1px solid #C44900;
                border-right: 1px solid #C44900;
                border-bottom: 1px solid #C44900;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 0px 0px 20px 0px;
            }
        }
    }
}

.question-container {
    max-width: 600px;
    // padding: 0px 0px 40px 0px;
    margin: auto;
    color: #ffffff;
}

.symptoms-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    color: #ffffff;


    .symptoms-header-question {
        text-align: left;
        margin: 0px;
    }
}

.reset-button {
    color: #ffffff;
    text-decoration: underline;
    margin: 0px 30px;
}

.single-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $light-green;
    padding: 10px 0px;
    color: #ffffff;

    .question-text {
        margin-right: 20px;
        text-align: left;
    }

    &:last-of-type {
        border-bottom: 1px solid $light-green;
        border-top: 0px;
    }
}

.yes-no-question-text {
    margin-bottom: 20px;
}

.yes-no-buttons {
    display: flex;

    .y-n-button {
        border: 1px solid #f8f9fa;
        padding: 5px;
        border-radius: 2px;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;

        &+.y-n-button {
            margin-left: 5px;
        }

        &:hover {
            cursor: pointer;
        }

        &.checked {
            &.red {
                background-color: #dc3545;
                border: 1px solid #dc3545;
            }

            &.green {
                background-color: #28a745;
                border: 1px solid #28a745;
            }
        }

        .check {
            width: 26px;
        }

        .times {
            width: 18px;
        }
    }
}