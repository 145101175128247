.user-picture {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-img {
        height: 16rem;
        border-radius: 1rem;
        display: block;
        margin: auto;
        min-width: 6rem;
    }

    .image-container {
        position: relative;
        height: 16rem;
    }


    // border: 1px solid black;
}