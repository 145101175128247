.main {
    display: flex;
    min-height: 100vh;

    & .left-container {
        max-width: 200px;
        padding: 40px 40px;
    }

    & .right-container {
        width: 100%;
        color: #000000;
        background-color: #ffffff;
        // border-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // padding: 40px;
        box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
    }
}