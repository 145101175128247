.button {

    &.btn {
        font-size: 1.2rem !important;
        display: flex;
        align-items: center;
    }

    &-edit {
        img {
            width: 1rem;
            height: 1rem;
            margin-right: 8px;
        }
    }

    &-upload {
        background-color: $dark-green !important;
        border-color: $dark-green !important;

        img {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 8px;
        }
    }

    &-close {
        background-color: $gray !important;
        border-color: $gray !important;

        img {
            width: 1.5rem;
            height: 1.5rem;
        }

    }

    &-search {
        background-color: $dark-green !important;
        border-color: $dark-green !important;

        img {
            width: 1rem;
            height: 1rem;
            margin-right: 8px;
        }
    }




}