.logout {
    padding: 20px;
    box-shadow: $box-shadow;
    background: white;
    margin: 4rem auto;
    max-width: 1200px;
    font-size: 16px;
    border-radius: $input-border-radius;

    p {
        max-width: 800px;
    }
}