.header {
    padding: 40px;
    background-color: $light-green;
    min-height: 200px;

    & .title-row {
        display: flex;
        justify-content: space-between;

        & h3 {
            margin-top: 0;
            width: 14rem;
        }

        & .avatar-container {
            display: flex;
            justify-content: center;
            align-items: center;
            // background: rgb(219, 218, 218);
            padding: 10px;
            border-radius: $input-border-radius;
            color: $dark-green;
            font-weight: 500;

            & .avatar {
                border-radius: 10px;
                margin-right: 10px;
                width: 60px;
                height: 60px;
                object-fit: cover;
                display: inline-block;
            }
        }
    }

    & .barcode-row {
        display: flex;
        justify-content: center;
        // margin-top: 3rem;
    }

    .location-banner {
        color: #f3f3f3;
        background-color: #194c4f;
        border-color: #c3e6cb;
        border-radius: .25rem;
    }

}