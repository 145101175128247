.scanned-container {
    // max-width: 800px;
    // margin-top: 4rem;

    & .submit-container {
        display: flex;
        justify-content: center;
        margin-top: 2rem;


    }

    & .message-container {
        display: flex;
        justify-content: center;
        // margin-top: 2rem;
        width: 100%;
        font-size: 1.25rem;

        .message {
            font-size: 1.75rem;
            color: $bright-green;
        }

    }
}