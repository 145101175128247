html,
body {
    font-family: "adelle-sans", sans-serif;
    font-size: 16px;
    // color: #ffffff;
}

label {
    color: #000000;
}

h1 {
    font-weight: 600;
    font-size: 26px;
}

h2 {
    font-weight: 400;
}