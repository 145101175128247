.dialog {
    &-attestation {

        & .modal-header {
            padding: 0;

            & h5 {
                color: white;
            }

            & .close {
                color: white;
            }
        }

        & .modal-content {
            background: $dark-green;
            padding: 2rem;
        }
    }
}