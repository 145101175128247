.user-info {
    // display: flex;
    // justify-content: center;
    max-width: 1000px;
    margin: auto;
    padding: 20px 10px 10px 10px;
    position: relative;

    & .label {
        font-size: 1.4rem;
    }

    & .detail-row {
        border-bottom: 1px solid #80808080;
        padding-bottom: .5rem;
        margin-bottom: .75rem;

        & .col {
            padding: 0;
        }

        &.no-border {
            border-bottom: none !important;
        }
    }

    & .last-row {
        color: #23676b;
    }

    & .detail-col {
        text-align: right;
    }
}

.user-view {
    padding: 0 2rem 2rem 2rem;

    & .reprint-container {
        display: flex;
        justify-content: center;

        & button {
            // margin-top: 28px;
        }
    }
}

.user-edit {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background: white;
    /* margin: auto; */
    /* max-width: 90vw; */
    box-shadow: $box-shadow;

    & .action-container {
        display: flex;
        justify-content: flex-end;
    }
}


.user-option-btn.btn {
    background: transparent;
    border: none;
}