.waiting-to-scan {
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 404px;
    margin-top: 4rem;

    img {
        width: 4rem;
        margin-bottom: 1rem;
    }

}