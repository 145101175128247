.printer {


    &-container {
        // text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        max-width: 25rem;
        margin: auto;
        margin-top: 4rem;

        & .title {
            text-align: center;
            margin-bottom: 2rem;
        }

        td {
            border-radius: $input-border-radius;
        }
    }

    &-list {
        margin-bottom: 0px !important;

        td {
            // border-radius: $input-border-radius;
            cursor: pointer;
        }

        & .selected {
            background: $light-green;
        }
    }

    &-popover-container {
        border-radius: $input-border-radius;
    }

    &-button {
        &.btn {
            background-color: transparent;
            border: none;
            margin-right: 1rem;
        }

        & img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }


}